.blog {
	.blog-entry {
		display: block;
		position: relative;
		margin-bottom: 30px;

		box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.1);

		&:hover {
			img {
				opacity: .5;
			}

			a {
				color: var(--white);
			}
		}

		img {
			width: 100%;
			left: 0;
			top: 0;
			z-index: 1;
			transition: all .25s ease 0s;
			height: 250px;
			object-fit: cover;
		}

		.blog-overlay-cont {
			position: relative;
			text-align: left;
			padding: 15px;
			background-color: var(--white);

			h3 {
				color: var(--black);
				margin-top: 0;
			}

			.bl-dt {
				color: var(--white);
				background-color: var(--primary);
				padding: 3px 5px;
				display: inline-block;
				margin-bottom: 15px;
			}

			.tags {
				min-height: 40px;
				padding: 0;

				li {
					display: inline-block;
					color: var(--secondary);
					list-style: none;
					padding-right: 15px;
					font-weight: bold;
					text-transform: capitalize;

					&:after {
						color: var(--black);
						position: relative;
						left: -3px;
					}
				}
			}
		}
	}


	.blog-header {
		margin-bottom: 10px;
	}

	h2 {
		font-size: 24px;
		margin-top: 0;
	}
}

.blogentry {
	padding-top: 100px;

	.tag-box {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 5px 0;

			a {
				background-color: var(--primary);
				padding: 5px;
				display: block;
				color: var(--white);

			}
		}
	}
}